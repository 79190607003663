<template>
  <!-- 确认订单 -->
  <div id="app">
    <div class="cart-con confirm-con pay-con">
      <div class="cart-ban">
        <img src="@/assets/img/confirm-ban.png" />
      </div>
      <div class="cson-container">
        <div class="current">
          <ul>
            <li>
              <img src="@/assets/img/confirm-cur1.png" />
              <p class="cur">确认客户订单</p>
              <span></span>
            </li>
            <li>
              <img src="@/assets/img/confirm-cur22.png" />
              <p class="cur">订单支付</p>
              <span></span>
            </li>
            <li>
              <img src="@/assets/img/confirm-cur3.png" />
              <p>支付结果</p>
            </li>
          </ul>
        </div>
        <div class="tips">
          请在倒计时<span>{{ minutes }}:{{ seconds }}</span
          >结束前完成支付，超时订单将自动取消
        </div>
        <div class="orderNum">订单号：{{ orderData.orderNumber }}</div>
        <div class="payway">
          <div class="title">
            人民币支付
            <div class="pay-qes">
              支付遇到问题？<span @mouseenter="enter()" @mouseleave="leave()"
                >点我</span
              >
            </div>
            <div v-if="tipsshow" class="pay-qes-con">
              <img v-if="qr" :src="qr.imageUrl" />
              <h2>扫描微信二维码 课程和网站疑问</h2>
              <p>针对课程有疑问? 购买时遇到问题?</p>
            </div>
          </div>
          <!--在线支付-->
          <div class="choose">
            <ul>
              <li
                v-for="(item, index) in onlinepays"
                :key="index"
                :class="item.checked ? 'active' : ''"
                @click="choose(index)"
              >
                <img v-if="!item.checked" :src="item.img" />
                <img v-if="item.checked" :src="item.imgCur" />
                <span>{{ item.tags }}</span>
              </li>
            </ul>
            <div class="tips" style="display: none">支持Chase Quickpay转账</div>
          </div>
          <div v-if="onlinepays[2].checked" class="huabei">
            <ul>
              <li
                v-for="(item, index) in huabei"
                :key="index"
                :class="item.checked ? 'active' : ''"
                @click="choosehuabei(index)"
              >
                <h2>
                  {{ `￥${item.costPerPeriod} x ${item.periods}期` }}
                </h2>
                <p>
                  {{ `手续费 ￥${item.eachFee}/期` }}
                </p>
                <button>选择该支付方式</button>
              </li>
            </ul>
          </div>
          <!--分期付款-->
          <div class="title">美元支付</div>
          <div class="choose">
            <ul>
              <li
                v-for="(item, index) in fenqipays"
                :key="index"
                :class="item.checked ? 'active' : ''"
                @click="change(index)"
              >
                <img v-if="!item.checked" :src="item.img" />
                <img v-if="item.checked" :src="item.imgCur" />
                <span>{{ item.tags }}</span>
              </li>
            </ul>
            <p class="o-titile">支持美金分期、信用卡等付款方式</p>
          </div>

          <div class="title" style="display: none">
            转账支付<label>*人民币付款有优惠</label>
          </div>
          <div class="choose" style="display: none">
            <ul>
              <li
                v-for="(item, index) in zhuanpays"
                :key="index"
                :class="item.checked ? 'active' : ''"
                @click="check(index)"
              >
                <img
                  v-if="!item.checked"
                  :class="index === 1 ? 'small' : ''"
                  :src="item.img"
                />
                <img
                  v-if="item.checked"
                  :class="index === 1 ? 'small' : ''"
                  :src="item.imgCur"
                />
                <span>{{ item.tags }}</span>
              </li>
            </ul>
          </div>
        </div>
        <div class="forpay">
          <div class="total">
            <div v-if="fenqipays[0].checked" style="color: #de1306">
              （课程费用+手续费）
            </div>
            应付金额：<span
              v-if="fenqipays[0].checked || fenqipays[1].checked"
              class="sure"
              style="width: auto; margin: 0 20px 0 10px"
              >${{ orderData.payPalPrice || 0 }}</span
            >
            <span v-else class="sure" style="width: auto; margin: 0 20px 0 10px"
              >¥{{ orderData.rmbPayAmount || 0 }}</span
            >
            <button @click="nextpay">立即支付</button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="dialogFormVisible" class="submit-con">
      <div class="box zhuan-box">
        <h2>转账支付，请扫码</h2>
        <img class="code" src="@/assets/img/kefu-code.png" />
        <h3>请扫码添加好友，并备注【用户名】</h3>
        <p>1 ．典金付软裸额外支付 520 手埃倪；</p>
        <p>2 ．转发任意课捏 1 传海报，可获得支付优息，详询宫服．</p>
        <img
          class="close"
          src="@/assets/img/close-icon.png"
          @click="dialogFormVisible = false"
        />
      </div>
    </div>

    <!-- 等待完成支付弹窗 -->
    <div v-if="fdialogFormVisible" class="submit-con">
      <div class="box zhuan-box pay-box">
        <img class="code" src="@/assets/img/wait-icon.png" />
        <h3>等待您完成支付...</h3>
        <p>请在支付页面继续完成支付</p>
        <button @click="paymentComplete">支付完成</button>
        <p @click="fdialogFormVisible = false">
          <img src="@/assets/img/refresh-icon.png" />重新选择支付方式
        </p>
        <img
          class="close"
          src="@/assets/img/close-icon.png"
          @click="fdialogFormVisible = false"
        />
      </div>
    </div>

    <!-- 移步到订单中心 -->
    <div v-if="dialogFormVisibleMoving" class="submit-con">
      <div class="box zhuan-box pay-box" style="height: 175px; width: 300px">
        <div class="c-title">订单超时，此订单已失效</div>
        <div class="c-btn">
          <div @click="showOrder()">移步订单中心</div>
        </div>
      </div>
    </div>
    <!-- paypal 容器 -->
    <div class="paypal_cont" v-show="paypalFlag">
      <div class="paypal_text">订单提交成功，请尽快付款！</div>
      <div id="paypal_cont" v-show="paypalFlag"></div>
      <div class="paypal_money">
        应付金额<span
          v-if="fenqipays[0].checked || fenqipays[1].checked"
          class="sure"
          style="width: auto; margin: 0 20px 0 10px"
          >${{ orderData.payPalPrice || 0 }}</span
        >
        <span v-else class="sure" style="width: auto; margin: 0 20px 0 10px"
          >¥{{ orderData.actualAmount || 0 }}</span
        >
      </div>
    </div>
    <div class="mask" v-show="paypalFlag" @click="cancelPaypal"></div>
    <!-- paypal 弹窗 -->
    <div v-if="dialogFormVisiblePaypal" class="submit-con">
      <div
        class="box zhuan-box pay-box"
        style="width: 388px; padding: 30px; height: 360px"
      >
        <div class="paypal-success-top">
          <div>订单提交成功，请尽快付款！</div>
        </div>

        <div class="paypal-success-bottom">
          <div>应付金额 $<span class="color:red;">104.90</span></div>
          <div>
            <p style="cursor: pointer" @click="dialogFormVisiblePaypal = false">
              <img
                src="@/assets/img/refresh-icon.png"
                style="cursor: pointer"
              />重新选择支付方式
            </p>
            <img
              class="close"
              src="@/assets/img/close-icon.png"
              @click="dialogFormVisiblePaypal = false"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  getCourseBanner,
  huabeiQuery,
  paymentCheck,
  payOrder,
  getPaypalClientId,
} from "../../service";
import { navigateBack } from "@/utils/common";
import { mapState } from "vuex";
import { getOrder } from "../../service/index";
import { loadScript } from "@paypal/paypal-js";
export default {
  data() {
    return {
      paypalFlag: false,
      qr: null,
      minutes: 15,
      seconds: "00",
      onlinepays: [
        {
          img: require("@/assets/img/pay-zhifubao1.png"),
          imgCur: require("@/assets/img/pay-zhifubao11.png"),
          checked: true,
          paytype: "alipay",
        },
        {
          img: require("@/assets/img/pay-wechat1.png"),
          imgCur: require("@/assets/img/pay-wechat11.png"),
          checked: false,
          paytype: "wechat",
        },

        {
          img: require("@/assets/img/pay-huabei1.png"),
          imgCur: require("@/assets/img/pay-huabei11.png"),
          checked: false,
          tags: "分期付款",
          paytype: "huabei",
        },
      ],
      isOnlinePay: true,
      fenqipays: [
        {
          img: require("@/assets/img/pay-paypal1.png"),
          imgCur: require("@/assets/img/pay-paypal11.png"),
          tags: "",
          checked: false,
          paytype: "paypal",
        },
        {
          img: require("@/assets/img/pay-affirm1.png"),
          imgCur: require("@/assets/img/pay-affirm11.png"),
          tags: "分期付款",
          checked: false,
          paytype: "",
        },
      ],
      isFenqiPay: false,
      zhuanpays: [
        {
          img: require("@/assets/img/pay-zelle1.png"),
          imgCur: require("@/assets/img/pay-zelle11.png"),
          tags: "额外支付$10手续费",
          checked: false,
          paytype: "",
        },
        {
          img: require("@/assets/img/pay-venmo1.png"),
          imgCur: require("@/assets/img/pay-venmo11.png"),
          tags: "额外支付$10手续费",
          checked: false,
          paytype: "",
        },
      ],
      isZhuanPay: false,
      tipsshow: false,
      huabei: [
        {
          label: "花呗支付",
          money: "¥1346",
          checked: true,
        },
      ],
      dialogFormVisible: false,
      orderData: {},
      cur_order_id: "",
      payMethod: {},

      fdialogFormVisible: false,
      payData: "",
      orderId: "",
      orderInfo: "",

      dialogFormVisiblePaypal: false,
      credentials: {
        sandbox:
          "AfA2Q68WavAmCIqN-CoRLJgdZ5y6NPGu70Kid1osijZtcW_sGVly--vh2GTXdkFbfrReVkZYDNYrKiQJ",
        production:
          "AT11nj3iKI-uoV50hvCot0XHAJE50_7h-FXR0tiVpqMZA0aRJnOzfgUwaH1rat9qnLDIhqR9G6DCcq28",
      },

      buttonStyle: {
        label: "pay",
        size: "large", // small | medium | large | responsive
        shape: "rect", // pill | rect
        color: "gold", // gold | blue | silver | black
      },
      curPayData: {},
      curOrderData: {},
      intervals: null,
      dialogFormVisibleMoving: false,
      clientId: "",
    };
  },
  created() {},
  mounted() {
    // getPaypalClientId().then((res) => {
    //   if (res.success) {
    //     this.clientId = res.result;
    //   }
    // });
    this.orderId = this.$route.query.orderId;
    if (this.orderId) {
      getOrder(this.orderId).then((res) => {
        if (res.success) {
          this.orderData = res.result;
          //订单倒计时
          this.setOrderTimeInterval();
          if (this.orderData.code) {
            this.$message.error(this.orderData.msg);
            window.history.back();
          } else {
            if (this.userInfo.status.value !== 2) {
              this.$message.warning("您的账户未实名认证，请支付完成后尽快实名");
            }
          }
        }
      });
      //
    } else {
      // orderId未传参
      this.$message.error("参数错误");
      navigateBack(-1, 2000);
      return;
    }

    this.choose(0); //默认支付宝支付

    this._huabeiQuery();
  },
  watch: {
    seconds: function (val) {
      if (this.minutes == "00" && this.seconds == "00") {
        this.dialogFormVisibleMoving = true;
      }
    },
    $route(to, from) {
      console.log(to);
      if (to.name == "orderpay") {
        if (this.intervals) {
          clearInterval(this.intervals);
        }
      }
    },
  },
  computed: mapState(["userInfo"]),
  destroyed() {
    if (this.intervals) {
      clearInterval(this.intervals);
    }
  },

methods: {
    cancelPaypal() {
      this.paypalFlag = false;
      this.$router.go(0);
    },
    showOrder() {
      this.$router.push("/center/order");
    },
    // 倒计时
    num: function (n) {
      return n < 10 ? "0" + n : "" + n;
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },

    setOrderTimeInterval() {
      let createTime = this.orderData.createTime;
      // let createTime = "2021-02-04 14:48:00";
      this.intervals = setInterval(() => {
        let m = this.$moment().diff(this.$moment(createTime), "s");
        if (m > 15 * 60) {
          clearInterval(this.intervals);
          this.dialogFormVisibleMoving = true;
        } else {
          this.minutes = this.num(Math.floor((15 * 60 - m) / 60));
          this.seconds = this.num((15 * 60 - m) % 60);
        }
      }, 1000);
    },
    /**
     *  选择在线
     */
    choose(index) {
      // 关闭分期
      for (let i = 0; i < this.fenqipays.length; i++) {
        this.fenqipays[i].checked = false;
      }
      this.isFenqiPay = false;
      //关闭转账
      for (let i = 0; i < this.zhuanpays.length; i++) {
        this.zhuanpays[i].checked = false;
      }
      this.isZhuanPay = false;
      //选择在线支付方式
      this.onlinepays.forEach((element, i) => {
        if (i === index) {
          element.checked = true;
        } else {
          element.checked = false;
        }
      });
      this.isOnlinePay = true;

      this.payMethod = this.onlinepays[index] || null;
    },
    //选择分期
    change(index) {
      if (index === 1) {
        this.$message.info("暂不支持，敬请期待");
        return;
      }
      for (let i = 0; i < this.onlinepays.length; i++) {
        this.onlinepays[i].checked = false;
      }
      this.isOnlinePay = false;
      for (let i = 0; i < this.zhuanpays.length; i++) {
        this.zhuanpays[i].checked = false;
      }
      this.isZhuanPay = false;
      this.fenqipays.forEach((element, i) => {
        element.checked = i === index;
      });
      this.isFenqiPay = true;
      this.payMethod = this.fenqipays[index];
      console.log(this.payMethod);
    },
    //选择转账
    check(index) {
      // if (this.fenqipays[0].checked || this.fenqipays[1].checked) {
      //     this.orderData.actualAmount = "$" + this.orderData.actualAmount;
      // } else {
      //     this.orderData.actualAmount = "¥" + this.orderData.actualAmount;
      // }

      for (let i = 0; i < this.onlinepays.length; i++) {
        this.onlinepays[i].checked = false;
      }
      this.isOnlinePay = false;
      for (let i = 0; i < this.fenqipays.length; i++) {
        this.fenqipays[i].checked = false;
      }
      this.isFenqiPay = false;
      this.zhuanpays.forEach((element, i) => {
        if (i == index) {
          element.checked = true;
        } else {
          element.checked = false;
        }
      });
      this.isZhuanPay = true;
    },

    enter() {
      this.tipsshow = !this.tipsshow;

      // 拿二维码图片
      getCourseBanner(4)
        .then((res) => {
          console.log(res);
          if (!res.result.code) {
            this.qr = res.result[0];
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    leave() {
      this.tipsshow = !this.tipsshow;
    },

    showTips() {
      this.tipsshow = !this.tipsshow;
    },
    choosehuabei(index) {
      this.huabei.forEach((element, i) => {
        if (i == index) {
          element.checked = true;
        } else {
          element.checked = false;
        }
      });
    },

    // 立即支付
    nextpay() {
      // if (this.isZhuanPay) {
      //     this.dialogFormVisible = true
      //     return
      // }
      // if (this.isOnlinePay) {
      //     this.$router.push('/paywechat')
      // }
      if (!this.payMethod || !this.payMethod.paytype) {
        this.$message.error("支付方式错误");
        return;
      }

      if (this.payMethod.paytype === "paypal") {
        this.paypalFlag = true;
        const that = this;
        loadScript({
          "client-id": process.env.VUE_APP_PAYPAL_CLIENT_ID,
          vault: true,
        }).then((paypal) => {
          paypal
            .Buttons({
              createOrder: function (data, actions) {
                console.log("_getOrder", that._getOrder());
                return that._getOrder();
              },
              onApprove: function (data, actions) {
                return that._paypalCallback(data.orderID);
              },
            })
            .render(".paypal_cont");
        });
      } else {
        let form = new FormData();
        form.append("mobile", false);
        if (this.payMethod.paytype === "huabei") {
          let index = this.huabei.findIndex((p) => p.checked);
          form.append("number", this.huabei[index].periods);
        }
        // 支付宝支付、微信支付
        console.log(form);
        payOrder(this.payMethod.paytype, this.orderData.orderId, form)
          .then((res) => {
            console.log(form);
            if (res.success) {
              if (!res.result.code) {
                let orderId = this.$route.query.orderId;
                let result = res.result;
                console.log(this.payMethod);

                // setStorage(`cur_pay_${orderId}`, res.result, true);

                console.log(this.payMethod.paytype);
                // console.log(this.payMethod.paytype);
                // 支付宝支付
                if (
                  this.payMethod.paytype === "alipay" ||
                  this.payMethod.paytype === "wechat" ||
                  this.payMethod.paytype === "huabei"
                ) {
                  console.log(this.userInfo);
                  let orderId = this.$route.query.orderId;
                  if (!orderId) {
                    this.$message.error("订单数据错误，请重新下单");
                    navigateBack(-1, 2000);
                    return;
                  }
                  this.orderId = orderId;
                  //订单数据
                  //   this.orderInfo = getCurCreateOrder();
                  if (this.orderData.code) {
                    this.$message.error(
                      this.orderData.msg || "系统错误-1000003"
                    );
                    this.$router.replace("/confirm");
                    return;
                  }
                  this.setPayType();
                  localStorage.setItem("payData", JSON.stringify(result));
                  if (res.result.action === "showForm") {
                    let route = this.$router.resolve({
                      path: `/payform?orderId=${this.orderId}`,
                    });
                    window.open(route.href, "_blank");
                  }

                  if (res.result.action === "showLink") {
                    this.$router.push({
                      path: "/pay/confirm",
                      query: {
                        orderId: this.orderId,
                        payType: this.payMethod.paytype,
                      },
                    });
                  }
                  this.fdialogFormVisible = true;
                }
              } else {
                this.$message.error(res.message);
                navigateBack(-1, 0);
              }
            } else {
              this.$message.warning(res.message);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },

    /**
     * @description 获取订单编号
     */
    async _getOrder() {
      let curOrderId = this.$route.query.orderId; //获取订单ID
      let content;
      console.log("process", process);
      // process.env.VUE_APP_BASE_URL
      await fetch(
        process.env.VUE_APP_BASE_URL + "order/payment/paypal/" + curOrderId,
        {
          method: "POST",
          headers: {
            "X-Access-Token": window.localStorage.getItem("CSON_PAGE_TOKEN"),
          },
        }
      )
        .then((resp) => {
          return resp.json();
        })
        .then((data) => {
          content = data.result.content;
        });
      return content;
    },

    async _paypalCallback(paypalOrderId) {
      let content;

      await fetch(
        process.env.VUE_APP_BASE_URL +
          "payment/callback/paypal?orderId=" +
          paypalOrderId,
        {
          method: "POST",
          headers: {
            "X-Access-Token": window.localStorage.getItem("CSON_PAGE_TOKEN"),
          },
        }
      )
        .then((resp) => {
          return resp.json();
        })
        .then((data) => {
          content = data.result.content;
          this.$router.push("/pay/success");
        });
      return content;
    },

    _huabeiQuery() {
      let orderId = this.$route.query.orderId;
      huabeiQuery({ orderId })
        .then((res) => {
          if (!res.result.code) {
            this.huabei = res.result;
            if (this.huabei.length > 0) {
              this.huabei[0].checked = true;
            }
          }
        })
        .catch((err) => {});
    },

    // 等待完成支付弹窗 ---> 支付完成
    paymentComplete() {
      paymentCheck(this.orderId)
        .then((res) => {
          if (res.result) {
            // window.gtag("event", "conversion", {
            //   send_to: "AW-316705807/iMdfCJT_xO4CEI-YgpcB",
            //   transaction_id: this.orderId,
            // });
            this.$router.push("/pay/success");
          } else {
            this.$message.error("支付未完成或支付失败");
          }
          console.log("paymentCheck success", res);
        })
        .catch((err) => {
          console.log("paymentCheck fail", err);
        });
    },
    setPayType() {
      if (this.payData) {
        switch (this.payData.paytype) {
          case "alipay":
            this.payData.payTypeDesc = "支付宝";
            break;
          case "wechat":
            this.payData.payTypeDesc = "微信支付";
            break;
          case "huabei":
            this.payData.payTypeDesc = "花呗分期";
            break;
          case "paypal":
            this.payData.payTypeDesc = "PayPal";
            break;
        }
      }
    },
  },
};
</script>

<style scoped>
.paypal_money {
  text-align: center;
  position: absolute;
  bottom: 55px;
  left: 50%;
  transform: translateX(-50%);
}
.paypal_money span {
  color: #fd1d1d;
  font-size: 25px;
}
.paypal_text {
  text-align: center;
  margin: 30px;
}
.paypal_cont {
  z-index: 9999;
  position: fixed;
  /* top: 20%;
  left: 33%; */
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scale(1);
  background-color: #fff;
  padding: 50px 120px 120px;
  border-radius: 15px;
  border: 1px #eee solid;
}
.mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.paypal-success-top {
  font-size: 14px;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  color: #34495e;
  line-height: 20px;
  text-align: center;
}

.paypal-success-button {
  margin-top: 30px;
  margin-bottom: 30px;
}

.paypal-success-bottom {
  font-size: 16px;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  color: #34495e;
  line-height: 24px;
}

.paypal-success-bottom span {
  color: red;
  font-weight: bolder;
  font-size: 20px;
}

.paypal-success-bottom div {
  margin-bottom: 10px;
}

.o-titile {
  margin-top: 10px;
  font-size: 12px;
  color: #fa6400;
}

.c-title {
  text-align: center;
  padding: 20px;
}

.c-title div:nth-child(1) {
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.8);
  cursor: pointer;
}

.c-title div:nth-child(2) {
  margin-top: 12px;
  font-weight: 400;
  color: #fa6400;
  cursor: pointer;
}

.c-btn {
  display: flex;
  margin-bottom: 20px;
  margin-left: 16px;
  margin-top: 20px;
}

.c-btn div {
  font-size: 14px;
  font-weight: 500;
  padding: 5px 22px;
  color: rgba(0, 0, 0, 0.5);
  line-height: 18px;
  border-radius: 2px;
}

.c-btn div:nth-child(1) {
  color: white;
  background: linear-gradient(to left, #fd1d1d, #fcb045);
  margin-right: 20px;
  cursor: pointer;
}

.c-btn div:nth-child(2) {
  border: 1px solid rgba(0, 0, 0, 0.5);
  cursor: pointer;
}

.orderNum {
  padding: 10px 0;
}

.huabei ul li {
  cursor: pointer;
}
</style>
